import Immutable from 'seamless-immutable';
import { v4 as uuid } from 'uuid';
import { toast } from 'liber-components/components/Toast';
import {
  ANTICIPATION_SET_LOADING,
  ANTICIPATION_SET_CURRENT_STEP,
  ANTICIPATION_UPDATE_QUOTE,
  ANTICIPATION_UPDATE_BANK_ACCOUNTS,
  ANTICIPATION_SET_BANK_ACC_ID,
  ADVANCE_PROGRESS,
  ANTICIPATION_SET_DOCUMENT_READY,
  ANTICIPATION_UPDATE_ANTICIPATION,
  DOCUMENTS_ERROR,
  ANTICIPATION_SIGN_DOCUMENTS,
  DOCUMENTS_SIGNED,
  ANTICIPATION_SET_SHOW_SUMMARY,
  ANTICIPATION_SET_DOC_GENERATED,
  BORDERO_SETUP_ERROR,
  DOCUMENTS_READY,
  GENERATE_ANTICIPATION,
  ANTICIPATION_STEP_BACK_BUTTON,
  CLOSE_LIMIT_DIALOG,
  INSUFFICIENT_ANTICIPATION_LIMIT,
} from '../actions/AnticipationCheckoutActions';
import { PIN_TOKEN_ERROR_MESSAGE, TOAST_DURATION } from '../utils';
import { saveCurrentState, getCookieState } from '../cookieUtils';
import { INVOICES_PAGE_URL, CLEAN_ANTICIPATION_SIGN_URL } from '../urls';

export const initialState = Immutable({
  token: null,
  fornecedorToken: null,
  fornecedorId: null,
  vendorOtpRequired: null,
  displayBankAccountsCreationInstructions: true,
  operator: {
    id: null,
    cpf: '',
    name: '',
    isLegalAgent: true,
  },
  error: '',
  loading: false,
  currentStep: '',
  anticipationId: null,
  quote: {
    invoiceCount: '...',
    faceValue: '...',
    agreedRate: '...',
    discountValue: '...',
    netValue: '...',
  },
  bankAccounts: [],
  selectedBankAccountId: null,
  sentBankAccountId: null,
  advanceProgress: 0,
  isDocumentReady: false,
  anticipation: {
    borderos: [],
    payUpAt: null,
    total: {
      invoiceCount: 0,
      netValue: 'R$ 0,00',
      documentsUrl: '#',
    },
  },
  inputsError: false,
  buttonLoading: false,
  hasUserSigned: false,
  showSummary: false,
  wasDocumentGenerated: false,
  anticipationHasFailed: false,
  isAdmin: false,
  showLimitError: false,
  roomId: uuid(),
});

const getInitialState = () => {
  if (!window.location.href.includes(CLEAN_ANTICIPATION_SIGN_URL)) {
    return initialState;
  }

  const params = new URLSearchParams(window.location.search);
  const cookieState = getCookieState();

  const hasParams = Array.from(params.keys()).length > 0;
  const hasStateOnCookie = Object.keys(cookieState).length > 0;

  const shouldRedirect = !hasParams && !hasStateOnCookie;
  if (shouldRedirect) {
    window.location.href = INVOICES_PAGE_URL;
  }

  const paramId = params.get('id');
  const { anticipationId: savedId } = cookieState;

  const wasDocumentGenerated = params.get('generated') === 'true';
  const showSummary = params.get('summary') === 'true';

  if (paramId && paramId !== savedId) {
    return initialState.merge({
      anticipationId: paramId,
      showSummary,
      wasDocumentGenerated,
      isDocumentReady: wasDocumentGenerated,
    });
  }

  if (!hasParams) {
    return initialState.merge({ ...cookieState });
  }

  return initialState.merge({
    ...cookieState,
    wasDocumentGenerated,
    showSummary,
    isDocumentReady: wasDocumentGenerated,
  });
};

const getNewState = (state, action) => {
  switch (action.type) {
    case ANTICIPATION_SET_LOADING:
      return state.merge({
        loading: action.payload,
      });
    case ANTICIPATION_SET_CURRENT_STEP: {
      const {
        payload: { step },
      } = action;

      return state.merge({
        currentStep: step,
        anticipationHasFailed: false,
      });
    }
    case ANTICIPATION_UPDATE_QUOTE:
      return state.merge({
        quote: action.payload,
        loading: false,
      });
    case ANTICIPATION_UPDATE_BANK_ACCOUNTS:
      return state.merge({
        bankAccounts: action.payload,
        loading: false,
      });
    case ANTICIPATION_SET_BANK_ACC_ID: {
      const id = action.payload;
      return state.merge({
        selectedBankAccountId: id,
      });
    }
    case ANTICIPATION_STEP_BACK_BUTTON: {
      const { currentStep } = state;
      return currentStep !== 'Assinatura'
        ? state.merge({
            isDocumentReady: false,
            advanceProgress: 0,
          })
        : state;
    }
    case ADVANCE_PROGRESS: {
      const {
        payload: { progress, total },
      } = action;
      const { advanceProgress } = state;

      const updatedProgress = advanceProgress + (progress / total) * 100;
      if (updatedProgress > 99) {
        return state.merge({
          advanceProgress: 99,
        });
      }
      return state.merge({
        advanceProgress: updatedProgress,
      });
    }
    case BORDERO_SETUP_ERROR:
      return state.merge({
        anticipationHasFailed: true,
        advanceProgress: 0,
      });
    case ANTICIPATION_SET_DOCUMENT_READY:
      return state.merge({
        isDocumentReady: action.payload,
      });
    case GENERATE_ANTICIPATION: {
      const { selectedBankAccountId } = state;
      return state.merge({
        anticipationHasFailed: false,
        sentBankAccountId: selectedBankAccountId,
      });
    }
    case ANTICIPATION_UPDATE_ANTICIPATION: {
      return state.merge({
        anticipation: action.payload,
        loading: false,
      });
    }
    case DOCUMENTS_READY: {
      return state.merge({
        isDocumentReady: true,
        advanceProgress: 100,
      });
    }
    case DOCUMENTS_ERROR: {
      const {
        payload: { message },
      } = action;

      let inputsError = false;
      if (message === PIN_TOKEN_ERROR_MESSAGE) {
        inputsError = true;
      } else {
        toast({ message: 'Erro na assinatura', info: message }, 'error', TOAST_DURATION);
      }

      return state.merge({
        buttonLoading: false,
        anticipationHasFailed: true,
        inputsError,
        advanceProgress: 0,
      });
    }
    case ANTICIPATION_SIGN_DOCUMENTS:
      return state.merge({ buttonLoading: true, anticipationHasFailed: false, inputsError: false });
    case DOCUMENTS_SIGNED: {
      const {
        payload: { documents },
      } = action;

      const hasError = documents.filter(({ status }) => status === 'error').length > 0;

      if (hasError) {
        toast(
          {
            message: 'Erro, sua assinatura não foi realizada!',
            info: 'Tente novamente ou fale com o suporte',
          },
          'warning',
          TOAST_DURATION,
        );
      }

      return state.merge({
        buttonLoading: false,
        hasUserSigned: !hasError,
      });
    }
    case ANTICIPATION_SET_SHOW_SUMMARY:
      return state.merge({
        showSummary: action.payload,
      });
    case ANTICIPATION_SET_DOC_GENERATED:
      return state.merge({
        wasDocumentGenerated: action.payload,
      });
    case INSUFFICIENT_ANTICIPATION_LIMIT:
      return state.merge({
        showLimitError: true,
      });
    case CLOSE_LIMIT_DIALOG:
      return state.merge({
        showLimitError: false,
      });
    default:
      return state;
  }
};

export default (state = getInitialState(), action = {}) => {
  const newState = getNewState(state, action);

  if (window.location.href.includes(CLEAN_ANTICIPATION_SIGN_URL)) {
    saveCurrentState(newState);
  }
  return newState;
};
