import axios from 'axios';
import {
  ANTICIPATION_SUMMARY_URL,
  ADVANCES_ACTION_CABLE_ROOM,
  ADVANCES_ACTION_CABLE_CHANNEL,
  INVOICES_ACTION_CABLE_ROOM,
  INVOICES_ACTION_CABLE_CHANNEL,
  GENERATE_ANTICIPATION_URL,
} from '../urls';
import { INVOICES_BANK_ACCOUNTS_URL } from '../../fornecedor-duplicatas/urls';
import {
  ANTICIPATION_BORDEROS_URL,
  SIGN_DOCUMENTS_URL,
} from '../../../common-components/VendorAnticipations/urls';
import { handleError } from '../utils';
import { convertToCamelCase } from '../../../vendor/Utils';

export const ID_IN = 'q[id_in]';

export const ADVANCES_SUBSCRIBE = 'ADVANCES_SUBSCRIBE';
export const DOCUMENTS_READY = 'DOCUMENTS_READY';
export const CHECKOUT_SUBSCRIBE_INVOICES = 'CHECKOUT_SUBSCRIBE_INVOICES';
export const ANTICIPATION_SET_LOADING = 'ANTICIPATION_SET_LOADING';
export const ANTICIPATION_SET_CURRENT_STEP = 'ANTICIPATION_SET_CURRENT_STEP';
export const ANTICIPATION_UPDATE_QUOTE = 'ANTICIPATION_UPDATE_QUOTE';
export const ANTICIPATION_UPDATE_BANK_ACCOUNTS = 'ANTICIPATION_UPDATE_BANK_ACCOUNTS';
export const ANTICIPATION_SET_BANK_ACC_ID = 'ANTICIPATION_SET_BANK_ACC_ID';
export const ANTICIPATION_STEP_BACK_BUTTON = 'ANTICIPATION_STEP_BACK_BUTTON';
export const ADVANCE_PROGRESS = 'ADVANCE_AGGREGATE_PROGRESS';
export const ANTICIPATION_SET_DOCUMENT_READY = 'ANTICIPATION_SET_DOCUMENT_READY';
export const ANTICIPATION_UPDATE_ANTICIPATION = 'ANTICIPATION_UPDATE_ANTICIPATION';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';
export const ANTICIPATION_SIGN_DOCUMENTS = 'ANTICIPATION_SIGN_DOCUMENTS';
export const DOCUMENTS_SIGNED = 'DOCUMENTS_SIGNED';
export const ANTICIPATION_SET_SHOW_SUMMARY = 'ANTICIPATION_SET_SHOW_SUMMARY';
export const ANTICIPATION_SET_DOC_GENERATED = 'ANTICIPATION_SET_DOC_GENERATED';
export const GENERATE_ANTICIPATION = 'GENERATE_ANTICIPATION';
export const BORDERO_SETUP_ERROR = 'BORDERO_SETUP_ERROR';
export const CLEAR_URL = 'CLEAR_URL';
export const LIMITS_SUBSCRIBE = 'LIMITS_SUBSCRIBE';
export const CLOSE_LIMIT_DIALOG = 'CLOSE_LIMIT_DIALOG';
export const INSUFFICIENT_ANTICIPATION_LIMIT = 'INSUFFICIENT_ANTICIPATION_LIMIT';

export const setLoading = loading => ({ type: ANTICIPATION_SET_LOADING, payload: loading });
export const setCurrentStep = step => ({
  type: ANTICIPATION_SET_CURRENT_STEP,
  payload: { step },
});
export const updateBorderosQuote = quote => ({ type: ANTICIPATION_UPDATE_QUOTE, payload: quote });
export const stepBackButton = () => ({ type: ANTICIPATION_STEP_BACK_BUTTON });

export function subscribeAnticipation() {
  return (dispatch, getState) => {
    const {
      anticipationCheckout: {
        operator: { id },
      },
    } = getState();

    dispatch({
      type: ADVANCES_SUBSCRIBE,
      channel: ADVANCES_ACTION_CABLE_CHANNEL,
      room: ADVANCES_ACTION_CABLE_ROOM + id,
    });
  };
}

export const subscribeToInvoices = () => (dispatch, getState) => {
  const {
    anticipationCheckout: { fornecedorId },
  } = getState();
  dispatch({
    type: CHECKOUT_SUBSCRIBE_INVOICES,
    channel: INVOICES_ACTION_CABLE_CHANNEL,
    room: INVOICES_ACTION_CABLE_ROOM + fornecedorId,
  });
};

export const subscribeToLimits = () => (dispatch, getState) => {
  const {
    anticipationCheckout: { roomId },
  } = getState();
  dispatch({
    type: LIMITS_SUBSCRIBE,
    channel: 'AnticipationLimitsChannel',
    roomId,
  });
};

export function generateAnticipation() {
  return (dispatch, getState) => {
    const {
      anticipationCheckout: { token, anticipationId, selectedBankAccountId },
    } = getState();

    dispatch({ type: GENERATE_ANTICIPATION });

    return axios
      .post(
        GENERATE_ANTICIPATION_URL.replace(':id', anticipationId),
        { bank_account_id: selectedBankAccountId },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .catch(handleError);
  };
}

export function fetchBorderosQuote() {
  return (dispatch, getState) => {
    dispatch(setLoading(true));

    const {
      anticipationCheckout: { token, anticipationId },
    } = getState();

    return axios
      .get(ANTICIPATION_SUMMARY_URL.replace(':id', anticipationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(payload => {
        const {
          data: { data },
        } = payload;

        const formattedData = convertToCamelCase(data);
        dispatch(updateBorderosQuote(formattedData));
      })
      .catch(handleError);
  };
}

export const updateBankAccounts = bankAccounts => ({
  type: ANTICIPATION_UPDATE_BANK_ACCOUNTS,
  payload: bankAccounts,
});

export function fetchBankAccounts() {
  return (dispatch, getState) => {
    const {
      anticipationCheckout: { fornecedorToken: token, bankAccounts },
    } = getState();

    if (bankAccounts?.length) return null;

    dispatch(setLoading(true));

    return axios
      .get(INVOICES_BANK_ACCOUNTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(payload => {
        const {
          data: { data: dataArray },
        } = payload;

        const formattedData = dataArray.map(convertToCamelCase);

        dispatch(updateBankAccounts(formattedData));
      })
      .catch(handleError);
  };
}

export const setSelectedBankAccountId = id => ({ type: ANTICIPATION_SET_BANK_ACC_ID, payload: id });

export const setAdvanceProgress = progress => ({
  type: ADVANCE_PROGRESS,
  payload: { progress },
});

export const setIsDocumentReady = isReady => ({
  type: ANTICIPATION_SET_DOCUMENT_READY,
  payload: isReady,
});

export const updateAnticipation = anticipation => ({
  type: ANTICIPATION_UPDATE_ANTICIPATION,
  payload: anticipation,
});

export function fetchAnticipation() {
  return (dispatch, getState) => {
    dispatch(setLoading(true));

    const {
      anticipationCheckout: { token, anticipationId },
    } = getState();

    return axios
      .get(ANTICIPATION_BORDEROS_URL.replace(':id', anticipationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(payload => {
        const {
          data: { data: anticipation },
        } = payload;

        const formattedAnticipation = convertToCamelCase(anticipation);

        dispatch(updateAnticipation(formattedAnticipation));
      })
      .catch(handleError);
  };
}

export function signDocuments(pin, otp) {
  return (dispatch, getState) => {
    dispatch({ type: ANTICIPATION_SIGN_DOCUMENTS });

    const {
      anticipationCheckout: { anticipation, token, roomId },
    } = getState();

    const signingIds = anticipation.borderos.map(({ signingId }) => signingId);

    const payload = {
      signing_ids: signingIds,
      pin,
      room_id: roomId,
    };

    if (otp) payload.otp = otp;

    return axios
      .post(SIGN_DOCUMENTS_URL, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(handleError);
  };
}

export const setShowSummary = show => ({ type: ANTICIPATION_SET_SHOW_SUMMARY, payload: show });
export const setWasDocumentGenerated = generated => ({
  type: ANTICIPATION_SET_DOC_GENERATED,
  payload: generated,
});

export const closeLimitDialog = () => ({ type: CLOSE_LIMIT_DIALOG });
