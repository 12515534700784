import React from 'react';
import PropTypes from 'prop-types';
import {
  BackgroundContainer,
  BannerButton,
  BannerButtonWrapper,
  BannerSubtitle,
  BannerTextWrapper,
  BannerTitle,
} from './style';

export const Banner = ({ onClick }) => (
  <BackgroundContainer>
    <BannerTextWrapper>
      <BannerTitle>Precisa antecipar outras duplicatas?</BannerTitle>
      <BannerSubtitle>
        Tenha acesso a ofertas de antecipação Líber de forma segura e simples.
      </BannerSubtitle>
    </BannerTextWrapper>
    <BannerButtonWrapper>
      <BannerButton size="small" onClick={onClick} iconName="ArrowForward">
        Quero antecipar
      </BannerButton>
    </BannerButtonWrapper>
  </BackgroundContainer>
);

Banner.propTypes = {
  onClick: PropTypes.func.isRequired,
};
