import React from 'react';
import { Analytics } from '../../../../libs/googleAnalytics/index.js';

import { Container } from './styles';

import { Banner } from '../Banner';

const BANNER_URL_LINK = 'https://solucao.libercapital.com.br/liber-antecipacao-recebiveis';

const VendorInterest = () => {
  const onClick = () => {
    Analytics.emitCustomEvent('banner_mcms:request:v1');
    window.open(BANNER_URL_LINK, '_blank').focus();
  };

  return (
    <>
      <Container>
        <Banner onClick={onClick} />
      </Container>
    </>
  );
};

VendorInterest.propTypes = {};

export default VendorInterest;
