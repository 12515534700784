import axios from 'axios';

axios.interceptors.response.use(
  response => response,
  (error, response) => {
    if (error.response.status === 401) {
      window.location.href = `/operador/entrar`;
    }
    return response;
  },
);
